#root {
  .market-table {
    &__header-row {
      & > th:nth-child(1){
        width: 49px;
      }
      & > th:nth-child(2){
        width: 31.25%;
      }
      & > th:nth-child(3){
        width: 9.25%;
      }
      & > th:nth-child(4){
        width: 12.75%;
      }
      & > th:nth-child(5){
        width: 12.5%;
      }
      & > th:nth-child(6){
        width: 12.5%;
      }
      & > th:nth-child(7){
        width: 18.75%;
      }
    }

    &__outcome-row {
      & > td {
        min-width:fit-content;
      }
    }
  }

  .br {
    display: none;
  }
}

.popup-label {
  height: fit-content;
}

@media(max-width: 1300px) {
 #root .br {
    display: block;
  }
}
