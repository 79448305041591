#root {
  .dashboard-root {
    width: 100%;
    flex-wrap: wrap;
    display: flex;
    padding-top: 40px;
  }

  .dashboard-widget {
    flex: 1;
    flex-basis: 50%;
    padding: 0 15px 30px;

    & > div {
      margin-top: 0;
    }
  }

  .unsettled-markets table {
    margin: 0;
  }
}
