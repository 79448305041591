#root {
  & .origins-accordion {
    position: absolute;
    z-index: -100;
    top: 95%;
    padding: 0;
    margin: 0;
    transition: all .1s ease;
    opacity: 0;
    display: block;

    &.active {
      transition: all .1s ease, z-index;
      z-index: 100;
      opacity: 1;
      top: 100%;
      display: block;
    }

    & > div {
      margin: 0;
      max-height: 60vh;
      overflow: auto;
      border-color: #96c8da;
      box-shadow: 0 2px 3px 0 rgb(34 36 38 / 15%);
      border-radius: 0.28571429rem;
    }

    &__item {
      padding: .5rem 0 .5rem 1.8rem;

      &:last-child {
        padding-bottom: 0;
      }
    }

    &__input {
      display: flex;

      &.icon {
        &:hover {
          background: transparent;
        }
        & > input {
          padding-left: 4rem;
        }
      }
    }

    &__title {
      display: flex;
      padding: 0;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;

      & .dropdown-checkbox {
        width: 100%;
      }

      & > button {
        height: 18px;
        width: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &-wrapper_compact {
      width: 200px;
      min-width: fit-content;
    }

    &-wrapper_horizontal {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      gap: 1rem;

      & .origins-accordion__input {
        height: 3rem;
        width: 15rem;
      }
    }
  }

  & .origins-top-accordion {
    position: relative;
    min-height: 50px;
    display: flex;
    align-items: center;

    &__title {
      display: flex;
      align-items: center;

      &-button {
        margin: 0 !important;
        cursor: pointer;

        &-wrapper {
          margin-left: 1rem;
          display: inline-block;
          width: 2.4rem;
          height: 2.4rem;
          cursor: default;
        }
      }
    }

    &__item {
      &_active {
        background-color: rgb(240, 240, 240);

        &-light {
          background-color: rgb(247, 247, 247);
        }
      }
    }
  }
}

.searching-dropdown {

}

.origins-accordion__circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: red;
}
