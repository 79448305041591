#root {
  .market-override {
    &-container {
      margin-top: 50px;
      width: 60%;
      min-height: calc(100% - 50px);
      min-width: 700px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0 20px;
    }

    &-filters {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    &-message {
      width: 100%;
      text-align: center;
    }

    &-segment {
      width: 100%;
      border-radius: 0;
      border: .5px solid rgba(34,36,38,.15);

      &-loader {
        width: 100%;
        margin: 0;
        border-radius: 0;
        border-top: 0;
      }

      &-container {
        width: 100%;
        margin: 0 0 3rem;
      }

      &__item {
        display: inline-block;
        &:first-child {
          width: 6%;
        }
        &:nth-child(2) {
          width: 6%;
        }
        &:nth-child(3) {
          width: 5%;
        }
        &:nth-child(4) {
          width: 13%;
        }
        &:nth-child(5) {
          width: 55%;
        }
        &:nth-child(6) {
          width: 15%;
        }
      }

      & .drag-icon {
        opacity: 0
      }

      &:hover{
        background-color: #ECECEC;

        & .drag-icon{
          opacity: 1;
        }
      }

      &_drag {
        background-color: #ECECEC;

        & .drag-icon {
          opacity: 1
        }
      }

      &_header {
        background-color: #F9FAFB;
        font-weight: 700;
        margin: 1rem 0 0;
        border-radius: .2rem .2rem 0 0;
        border: 1px solid rgba(34,36,38,.15);

        &:hover {
          background-color: #F9FAFB;
        }
      }
    }
  }
}

.market-override__reset-portal {
  z-index: 1000;
  position: fixed !important;
  top: 2%;
  left: calc(50% - 170px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 340px;
  box-shadow: 1px 4px 13px 5px rgb(0 0 0 / 42%) !important;
}
