#root {
  .opponent-list {
    &__opp-input_error {
      & > input {
        border: 1px solid #e0b4b4;
        color: #9f3a38;
        background-color: #fff6f6;
      }
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-content: center;
    }

    &__opp-list-component {
      margin-top: 2rem;
    }

    &__list-warp {
      margin-top: 1rem;
      height: 280px;
      max-height: 280px;
      overflow-y: auto;
    }
  }
}
