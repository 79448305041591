.origin-settings {
  padding: 125px 0;
  width: 600px;
  margin: 0 auto;

  &__main-header {
    margin-left: 180px;
    font-weight: 900;
  }

  &__origins-dropdown {
    margin-left: 180px;

    & > input {
      padding-left: 1rem !important;
    }
  }

  &__block-wrapper {
    display: flex;
    align-items: flex-start;
    margin-top: 40px;
  }

  &__block-header {
    width: 180px;
    padding-right: 75px !important;
  }

  &__block-content {
    width: 420px;
    padding-top: 5px;
  }

  &__button-group {
    margin-top: 80px;
    display: flex;
    padding-left: 180px;
  }

  &__btn-delete {
    cursor: pointer;
  }

  .width-xxl {
    width: 332px !important;
  }
  .width-xl {
    width: 272px !important;
  }
  .width-l {
    width: 242px !important;
  }
  .width-m {
    width: 195px !important;
  }
  .width-s {
    width: 152px !important;
  }
  .width-xs {
    width: 99px !important;
  }
  .inline-element {
    margin-right: 15px !important;
    display: inline-block;
  }
  .inline-element-s {
    margin-right: 7px !important;
    display: inline-block;
  }
}
