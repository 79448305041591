.markets-modal-content {
  padding: 0 !important;
}
.markets-modal-content__grid {
  margin: 0 !important;
}
.list-of-markets {
  position: relative;
  padding: 1em 1em 5em 1em;
}

.list-of-markets__submit-row {
  position: sticky;
  bottom: -0.05em;
  right: 0;
  padding: 1em 0 1em;
  background: #ffffffde;
}
.list-of-markets__submit-row .button {
  margin-left: auto;
  display: flex;
}
