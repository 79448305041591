.ui.input.edited > input {
  color: #2185d0 !important;
}

.add-custom-bet {
  float: right;
}

#root .outcome-row__bets-cell {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
