#root {
  .limit-group__container {
    margin: 50px 10px;
  }

  .limit-group__row_high {
    background: rgb(254 187 87 / 18%);
  }

  .limit-group__cell-wrap {
    padding: 12px 6px;

    &_high {
      background: #ffb40070 !important;
    }
  }

  .limit-group__cell {
    position: relative;

    .limit-group__icon {
      position: absolute;
      cursor: pointer;
      right: 0;
      top: 0;

      &:hover {
        opacity: .7;
      }
    }

    & .input {
      max-width: 100%;
      padding-right: 20px;
    }

    &_static {
      cursor: pointer;

      .limit-group__icon-wrap {
        width: 10px;
      }
    }
  }
}
