.market-edit-form{
  &__modal-header {
    margin-top: 5px !important;
  }

  &__form-description-group {
    margin-bottom: 0;
  }

  &__form-outcome-group {
    align-items: end;
    margin-bottom: .5rem !important;
  }

  &__btn-delete-wrapper {
    height: 2em;
    width: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 4.5px;
    border-radius: .28571429rem;
    background: transparent;
    border: none;

    &:hover {
      & > .icon {
        color: #ec0000;
      }
    }

    & > .icon {
      margin: 0;
      padding: 0;
      color: #db2828;
      cursor: pointer;
    }
  }

  &__outcome-description-wrapper{
    padding: 0 7px;
    width: 55%;
  }

  &__outcome-price-wrapper {
    padding: 0 7px;
    width: 15%;
  }

  &__outcome-position-wrapper {
    width: 30%;
  }

  &__empty-first-block {
    width: 60%;
  }

  &__empty-second-block {
    width: 30%;
  }

  &__margin-total-block {
    width: 15%;
    padding: 0 7px;
    font-weight: bold;
  }

  &__outcomes-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 14px 0;

    & > div {
      display: flex;
      gap: 1rem;
    }
  }
}
