#root {
  .priceline {
    &-container {
      display: flex;
      flex-direction: column;
      gap: 12px;

      margin-top: 25px;

      .header {
        margin-bottom: 0;
      }

      & > * {
        width: fit-content;
      }
    }

    &__prices-wrapper {
      padding-top: 12px;
      min-width: 50%;
    }

    &__loader-wrap {
      position: relative;
      height: 50px;
    }

    &__row {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      gap: 8px;

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    &__filter {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      &:first-child {
        width: 170px;
      }

      &:nth-child(2) {
        width: 200px;
      }

      &:nth-child(3) {
        width: 100px;
      }

    }

    &__buttons {
      display: flex;
      gap: 15px;
    }
    &__checkbox{
      height: 21px;
      margin: auto;
    }
  }
}
