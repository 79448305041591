@import '../../styles/variables';

#root {
  & .market-status-stats-wrapper {
    width: 100%;
    display: flex;
  }

  & .market-status {
    position: relative;
    height: 11px;

    &_open {
      background-color: $COLOR_GREEN;
    }
    &_suspended {
      background-color: $COLOR_YELLOW;
    }
    &_closed {
      background-color: $COLOR_RED;
    }
    &_settled {
      background-color: $COLOR_BLUE;
    }
    &_resulted {
      background-color: $COLOR_LIGHT_GREY;
    }
  }

  & .market-status__span {
    position: absolute;
    font-size: 0.7em;
    padding-left: 2px;
    top: -4px;

    &_white {
      color: white;
    }
    &_black {
      color: black;
    }
  }
}
