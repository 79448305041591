.scoreboard-header-row {
  background-color: #f8f8f9;
  padding: 5px !important;
  font-weight: bold;
}

.opponent_description {
  font-size: 24px;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.scoreboard_buttons_panel {
  padding-top: 10px !important;
}

#root .scoreboard-block .divider {
  margin: 0;
}
