.RiskManager-FeedPrice,
.RiskManager-CurrentPrice {
  cursor: pointer;
}

.RiskManager-FeedPrice:hover,
.RiskManager-FeedPrice.active,
.RiskManager-CurrentPrice:hover .RiskManager-CurrentPrice__price,
.RiskManager-CurrentPrice.active .RiskManager-CurrentPrice__price {
  text-decoration: underline;
}

.RiskManager-CurrentPrice .icon {
  opacity: 0.2;
  transition: opacity 0.1s ease-in;
  margin-left: 5px;
  font-size: 12px;
}

.RiskManager-CurrentPrice:hover .icon,
.RiskManager-CurrentPrice.active .icon {
  opacity: 0.9;
}

.nowrap {
  white-space: nowrap;
}

#root .selection-container {
  min-height: 300px;
}

#root .selection__header {
}

#root .selection__table {
  margin-top: 0;
}

#root .content-header.singles-header {
  padding: 10px 1rem 0;
}
