.TabBetRestrictions-eventsCheckboxList {
  overflow: auto;
  max-height: 400px;
}
.TabBetRestrictions-searchField {
  width: 100%;
}

#root .allows-row {
  display: flex;
  align-items: center;
  gap: 1rem;
}
