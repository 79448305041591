#root {
  .origin-label {
    &_default {
      background: #eee;
      border: 1px solid gray;
    }
    &_tornadobet {
      color: #fff;
      background: #830202;
    }
    &_reloadbet {
      color: #575757;
      background: #ffcf1a;
      border: 1px solid gray;
    }
  }
}
