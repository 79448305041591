.match_header_points {
  font-size: 24px;
  padding-top: 5px !important;
  padding-bottom: 15px !important;
}

.scoreboard-header-row:hover {
  background-color: #f2f2f2;
}

.second_level_period_raw {
  padding: 5px !important;
  font-weight: bold;
  font-size: 16px;
}

.second_level_period_raw:hover {
  background-color: #f2f2f2;
}

.opened_second_level_period_raw {
  padding: 5px !important;
  font-weight: bold;
  font-size: 16px;
  background-color: #f9fafb;
}

.opened_second_level_period_raw:hover {
  background-color: #f2f2f2;
}

.third_level_period_raw {
  background-color: #f9fafb;
  padding: 5px !important;
}

.third_level_period_raw:hover {
  background-color: #f2f2f2;
  font-size: 14px;
}

.score_button {
  visibility: hidden;
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.scoreboard-header-row:hover .score_button {
  visibility: visible;
}

.second_level_period_raw:hover .score_button {
  visibility: visible;
}

.opened_second_level_period_raw:hover .score_button {
  visibility: visible;
}

.third_level_period_raw:hover .score_button {
  visibility: visible;
}

.closed_node_button {
  visibility: hidden;
}

.second_level_period_raw:hover .closed_node_button {
  visibility: visible;
}

.third_level_period_raw:hover .closed_node_button {
  visibility: visible;
}

.match-period-description {
  padding-top: 7px !important;
  font-size: 18px;
  padding-bottom: 15px !important;
}
