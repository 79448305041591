#root{
  .skeleton {
    margin: calc(2 * .21428571em) 0;
    height: 1.14285714em;
    position: relative;
    overflow: hidden;
    background-color: #C2C1C2;
    border-radius: 4px;

    &:nth-child(odd) {
      width: 70%;
    }

    &:nth-child(even) {
      width: 50%;
    }

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(
                      90deg,
                      rgba(#fff, 0) 0,
                      rgba(#fff, 0.2) 20%,
                      rgba(#fff, 0.5) 60%,
                      rgba(#fff, 0)
      );
      animation: shimmer 3s infinite;
      content: '';
    }

    @keyframes shimmer {
      100% {
        transform: translateX(100%);
      }
    }
  }

  .noContentHeader {
    padding-left: 24px;
  }

  .newLink {
    font-weight: 700;
    cursor: pointer;
  }

  .event-path-tree__filters-wrap {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}
