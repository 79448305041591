#root {
  .copy-btn-string {
    &__wrapper {
      min-width: fit-content;
      width: fit-content;
      cursor: pointer;

      &:hover .copy-btn-string__button {
        opacity: 1;
      }
    }

    &__no-space-wrapper {
      white-space: nowrap;
      display: inline-block;
    }

    &__button {
      opacity: 0;
      cursor: pointer;
      transition: opacity .3s ease-out;
      margin: 0 4px;

      &_visible {
        opacity: 1;
      }
    }

    &__text {
      display: inline-block;
      opacity: 0;
      transition: opacity .3s ease-out;

      &_visible {
        opacity: 1;
        transition: opacity .3s ease-out;
      }
    }
  }
}
