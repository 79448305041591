#root {
  .event-card {
    &__message {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;

      &-wrapper {
        flex: 1;
      }
    }
    &__copy-button {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}
