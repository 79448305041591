.ui.input.edited > input {
  color: #2185d0 !important;
}

.outcome-row {
  padding: 5px !important;
}

.null-liability {
  font-size: 12px;
}

.negative-liability {
  font-size: 12px;
  color: #f24333;
}

.positive-liability {
  font-size: 12px;
  color: #2f7c21;
}

.bets_block {
  font-size: 12px;
}
