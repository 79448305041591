body {
  padding: 0;
  height: 100%;
}

#root {
  overflow: auto;
  height: 100%;

  .phoenix-sidebar {
    transition: transform 0.3s;
    position: fixed;
    right: 0;
    top: 40px;
    z-index: 999;
    min-width: 700px;
    transform: translateX(100%);
    background: white;
    bottom: 0;
    overflow: auto;

    &.visible {
      transform: translateX(0);
    }

    &-dimmer {
      position: fixed;
      z-index: 998;
      top: 40px;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  .as-link {
    display: inline-block;
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: #d4d4d4;
    transition: text-decoration-color 0.2s, opacity 0.2s;

    &:hover {
      text-decoration: none;
      text-decoration-color: transparent;
      opacity: 0.8;
    }
  }

  .FlexDiv {
    display: flex;

    &--column {
      flex-direction: column;
    }

    &--flex {
      flex: 1;
    }

    &:not(.FlexDiv--column) > div:not(:first-child):last-child {
      margin-left: 15px;
    }
  }

  .market-header-row .FlexDiv,
  .outcome-row .FlexDiv {
    height: 100%;
    align-items: center;
  }

  .outcome-input-wrap > .ui.input > input {
    max-width: 110px;
  }

  .animation-flash {
    color: #f2f;
    animation: flash linear 3s infinite;
  }

  .ui.label.super-tiny-label {
    font-size: 0.6rem;
  }

  .bet-monitor-separator {
    background: #eee;
    height: 1px;
    margin: 3px 0;
  }

  .content {
    &-container {
      margin: 40px 0 0;
    }

    &-header {
      display: flex;
      padding: 1rem;
      align-items: center;
      justify-content: space-between;
    }

    &-header h2 {
      margin: 0;
    }
  }

  .singles-table {
    width: 100%;
    margin: 0;

    & > div {
      margin: 0;
      padding: 0;
    }
  }

  .user-page-container {
    margin-top: 50px;
    margin-bottom: 15px;
  }


  .bets-container {
    min-height: 90vh;

    & > div > div {
      padding: 15px;
    }
  }

  .event-edit-container .bets-container {
    margin: 0;

    & > div > div {
      &:first-child {
        padding: 0;
      }

      &:last-child {
        padding: 0 0 0 15px;
      }
    }
  }
}

.ui.table td {
  padding: 0 0.78571429em;
}

@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}
