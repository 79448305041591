.market-action-button {
  padding: 9em 13em;
}

.market-header-row {
  background-color: #f8f8f9;
  padding: 5px !important;
}

.description-header {
  display: inline-block;
  margin: 0 auto;
  font-size: 14px !important;
}

.description-column {
  padding-left: 0;
}

.description-div {
  display: flex;
}

.description-flex-div {
  flex: 1;
  position: relative;
}

.margin-price {
  font-size: 12px;
  padding-left: 11px;
}

.bad-margin-price {
  font-size: 12px;
  padding-left: 11px;
  color: red;
}

.outcomes_all_text {
  font-size: 12px;
  padding-right: 7px;
}

.bets_block {
  font-size: 12px !important;
}
