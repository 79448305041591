#root {
  .bets-table {
    &__header {
      display: inline-block;
    }

    &__row {
      &_first {
        background-color: rgba(0, 0, 50, 0.05);
      }
      &_second {
        background-color: rgba(0, 0, 50, 0.08);
      }
    }

    &__live-mode-wrapper {
      display: inline-block;
      float: right;
    }

    &-row__checkbox {
      margin-right: .5em;
    }
  }

  .trade-true {
    background-color: rgb(186, 220, 254, 20%);
  }
}
