@import "../../styles/variables";

#root {
  .header-layout-menu {
    background-color: $COLOR_PURPLE;
  }
  .header-layout-menu-dropdown {
    background-color: $COLOR_PURPLE;

    &__span {
      color: white;
    }
  }
}
