#root {
  .error-boundary {
    &__wrap {
      margin: 250px auto;
      width: 800px;
    }

    &__body {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      font-size: 1.2rem;
    }

    &__reload-button {
      margin: 0 auto;
    }
  }
}
