.checkbox {
  margin-right: .5em;
}

.label {
  display: flex;
  align-items: center;
}

.event_header {
  display: flex;
  align-items: center;
}

.percent_btn {
  margin: 0 15px !important;
  padding: 0 !important;
}
