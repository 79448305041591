.control-panel {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.panel-buttons-column {
  padding-left: 0 !important;
}

.market-buttons-group {
  margin-left: 14px !important;
}

.eye_button {
  margin-left: 3px !important;
}

.margin-panel {
  padding-right: 0 !important;
}
