#root {
  .bets-filters__status-filter {
    white-space: nowrap;
    margin-left: 15px;
    border-bottom: 1px solid #d0d0d0;
    display: inline-block;
    padding-bottom: 3px;
    cursor: help;
  }
}
