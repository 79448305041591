$transition: all .2s ease;
$transition-fast:  all .2s ease;

.container {
  padding: 40px 15px;
}

.header {
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 25px;
  }

  .right {
    min-width: 3px;
  }
}

.title {
  font-size: 28px;
  margin: 0;
  padding: 0 0 5px;
}

.topPanel {
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 1;
}

.topPanelLeft {
  transform: translateX(-122px);
  transition: $transition-fast;

  &_active {
    transform: none;
  }
}

.clearBtn {
  display: inline-block;
  width: 107px;
  margin-right: 15px !important;
}

.filterBtn {
  display: inline-block;
  width: 118px;
  margin-right: 30px !important;
}

.topPanelRight {
  display: flex;
  align-items: center;
}

.columnsCheckboxes {
  align-items: center;
  overflow-y: auto;
  gap: 14px;

  &_full {
    display: inline-flex;

    @media (max-width: 1350px) {
      display: none;
    }
  }

  &_light {
    display: none;

    @media (max-width: 1350px) {
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;

      position: absolute;
      top: 0;
      right: 170px;
      z-index: 100;

      margin: .7em 0 0;
      padding: 1em 1em;

      box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
      border-radius: 0.28571429rem;
      border: 1px solid rgba(34, 36, 38, 0.15);

      background: #fff;
      opacity: 0;
      transition: $transition-fast;
    }

    @media (max-width: 1126px) {
      margin: 0;
    }
  }
}

.show {
  opacity: 1;
}

.btnShowColumnsFilter {
  display: none !important;

  @media (max-width: 1350px) {
    display: block !important;
  }
}

.main {
  position: relative;
}

.filters {
  width: 240px;
  position: absolute;
  top: 0;
  left: -255px;
  transition: $transition;

  &_active {
    left: 0;
  }
}

.filter {
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 5px;
  }
}

.tableWrap {
  display: inline-block;
  width: 100%;
  padding-bottom: 50px;
  position: absolute;
  overflow-x: auto;
  top: 0;
  right: 0;
  left: 0;
  transition: $transition;

  &_active {
    left: 270px;
    width: calc(100% - 270px);
  }
}

.cell {
  padding: 8px !important;
}

.headerRow {
  & > th {
    &:first-child {
      max-width: 45px;
      min-width: 45px;
    }

    &:last-child {
      max-width: 70px;
      min-width: 70px;
    }
  }
}

.outcomeWrap {
  width: max-content;
  transition: $transition;

  &:not(:first-child) {
    margin-top: 8px;
  }

  & > * {
    display: inline;
    word-break: break-word;
  }
}

.rejectReason {
  border-bottom: 1px solid lightgrey;
  width: min-content;
}

.showMoreBtn {
  font-size: 14px;
  font-weight: 400;
  border: none;
  background: none;
  margin: 8px 0 0;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  &:hover {
    opacity: .7;
  }

  & > i {
    margin-bottom: 2px;
  }
}

.stakeFilterWrap {
  display: flex;
  flex-direction: column;

  & .stakeFilterHeader {
    font-size: 14px;
    padding-top: 7px;
    padding-bottom: 5px;
    color: rgba(191,191,191,.87);
  }

  & .stakeFilterContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    & > div {
      width: 107px;
    }
  }
}
