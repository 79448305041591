#root {
  .bets__row {
    .player-cell {}
  }

  .firstBetRow {
    background-color: #ffdbdb;
  }

  .bets__main-void-button {
    display: inline-block;
    width: fit-content;
    padding-left: 1.5em !important;
    padding-right: 1.5em !important;
    background: #6435c9;
    color: #FFFFFF;

    & > i {
      display: none;
    }
  }

  .bets__row-void-button {
    background: #6435c9;
    color: #FFFFFF;
    padding: 8px 12px !important;
    font-size: 12px;

    & > i {
      display: none;
    }

    & .menu {
      right: 0 !important;
      left: auto !important;
    }
  }

  .bets__status-cell {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .status-cell--title {
      display: inline;
      margin-top: 0;
      margin-bottom: 4px;
      font-size: inherit !important;
      line-height: inherit !important;
    }

    .status-cell--trader {
      max-width: 100px;
      display: inline-block;
      word-break: break-all;
    }
  }

  .bets__date-cell {
    white-space: nowrap;
  }

  .max-width-outcomes {
    &_1 {
      max-width: 1030px;
      @media (min-width: 1300px) {
        max-width: 1088px;
      }
    }

    &_2 {
      max-width: 980px;
      @media (min-width: 1300px) {
        max-width: 1088px;
      }
    }

    &_3 {
      max-width: 890px;
      @media (min-width: 1300px) {
        max-width: 1088px;
      }
    }

    &_4 {
      max-width: 800px;
      @media (min-width: 1300px) {
        max-width: 1088px;
      }
    }

    &_5 {
      max-width: 730px;
      @media (min-width: 1300px) {
        max-width: 1088px;
      }
    }

    &_6 {
      max-width: 590px;
      @media (min-width: 1300px) {
        max-width: 1088px;
      }
    }

    &_7 {
      max-width: 480px;
      @media (min-width: 1300px) {
        max-width: 1088px;
      }
    }

    &_8 {
      max-width: 420px;
      @media (min-width: 1300px) {
        max-width: 968px;
      }
    }

    &_9 {
      max-width: 350px;
      @media (min-width: 1300px) {
        max-width: 878px;
      }
    }

    &_10 {
      max-width: 308px;
      @media (min-width: 1300px) {
        max-width: 808px;
      }
    }

    &_11 {
      max-width: 308px;
      @media (min-width: 1300px) {
        max-width: 768px;
      }
    }

    &_12 {
      max-width: 308px;
      @media (min-width: 1300px) {
        max-width: 718px;
      }
    }

    &_13 {
      max-width: 308px;
      @media (min-width: 1300px) {
        max-width: 628px;
      }
    }

    &_14 {
      max-width: 308px;
      @media (min-width: 1300px) {
        max-width: 538px;
      }
    }

    &_15 {
      max-width: 308px;
      @media (min-width: 1300px) {
        max-width: 458px;
      }
    }
  }
}
