.container {
  padding: 1em;
}
.eventPathTree {
  height: calc(100vh - 80px);
  overflow-y: scroll;
  overflow-x: hidden;
  position: fixed !important;
  top: 50px;
  left: 15px;
  bottom: 15px;
  width: 300px;
}
.wrap {
  padding-top: 50px;
  margin-left: 315px;
}
.icon {
  font-size: 0.4em !important;
  float: right;
}
