$height-1: 1.4285em;
$height-2: 2*1.4285em;
$height-3: 3*1.4285em;
$height-4: 4*1.4285em;
$height-5: 5*1.4285em;
$height-6: 6*1.4285em;
$height-input: calc(0.5rem + 1.71428571em);

#root {
  .table-skeleton__cell {
    & > div {
      background: #F5F5F6;
      color: #F5F5F6;
      height: 100%;
    }

    &_input-height {
      height: $height-input;
    }

    &_1-height {
      height: $height-1;
    }

    &_2-height {
      height: $height-2;
    }

    &_3-height {
      height: $height-3;
    }

    &_4-height {
      height: $height-4;
    }

    &_5-height {
      height: $height-5;
    }

    &_height {
      padding: 0.4em 0.6em;
    }
  }
}
