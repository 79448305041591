#root {
  & .btn-save-all-prices {
    float: right;
    margin: 0;
  }

  & .dynamic-market-button-show-all {
    background-color: #eeeeee;
    cursor: pointer;
    padding: 0;
  }

  & .btn-edit-market {
    padding-top: .6em;
    padding-bottom: .6em;
  }

  & .flex-header {
    display: inline-block;
    margin: 0;
  }

  & .current-price-margin-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
  }
}
