.input-price > input {
  border-color: #4283CA;
}

#root {
  & .price-cell {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
  }

  & .input-price {
    width: 4.6rem;

    & > input {
      padding: 0.5rem .8rem;
    }


    &_blue {
      & > input {
        color: #4283CA;
      }
    }

    &_green {
      & > input {
        animation-name: greenBorder;
        animation-duration: 5s;
      }
    }

    &_red {
      & > input {
        animation-name: redBorder;
        animation-duration: 5s;
      }
    }
  }

  & .btn-feed-price {
    padding: 0.6rem 1rem;
    margin: 0;
    white-space: nowrap;

    &:hover {
      background-color: #4283CA;
      color: #FFFFFF;
    }
  }
}

@keyframes greenBorder {
  0% {
    border-color: rgba(4, 186, 0, 0.7);
    color: rgba(4, 186, 0, 1);
  }
  35% {
    border-color: rgba(4, 186, 0, 0.7);
    color: rgba(4, 186, 0, 1);
  }
  100% {
    border-color: rgba(34,36,38,.15);;
    color: rgba(0, 0, 0, 1);
  }
}

@keyframes redBorder {
  0% {
    border-color: rgba(201, 59, 51, .7);
    color: rgba(201, 59, 51, 1);
  }
  35% {
    border-color: rgba(201, 59, 51, .7);
    color: rgba(201, 59, 51, 1);
  }
  100% {
    border-color: rgba(34,36,38,.15);
    color: rgba(0, 0, 0, 1);
  }
}
