.scoreboard-block {
  padding: 20px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.markets-block {
  padding: 20px !important;
}

.scoreboard-div {
  margin: auto !important;
}

.markets-block-div {
  margin: auto !important;
}
